import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {KeyValue, TicketEscalateCommand, TicketRequestApprovalCommand, TicketResponse, TicketSearchCommand, TicketSearchResponse} from "./helpdesk.types";
import {Observable, of, ReplaySubject, tap} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TicketService {

    private _httpClient = inject(HttpClient);
    private _pendingCount: ReplaySubject<number> = new ReplaySubject<number>(1);

    public search$(command: TicketSearchCommand): Observable<TicketSearchResponse> {
        return this._httpClient.post<TicketSearchResponse>('api/helpdesk_ticket/search', command);
    }

    public create$(formData: FormData): Observable<TicketResponse> {
        return this._httpClient.post<TicketResponse>(`api/helpdesk_ticket`, formData);
    }


    public findByPublicId$(number: string): Observable<TicketResponse> {
        return this._httpClient.get<TicketResponse>(`api/helpdesk_ticket/public/${number}`);
    }

    public get priorities$(): Observable<KeyValue[]> {
        return of(priorityValues);
    }

    public get statuses$(): Observable<KeyValue[]> {
        return of(statusValues);
    }

    assignTo$(ticketId: number, assignTo: number): Observable<TicketResponse> {
        return this._httpClient.put<TicketResponse>(`api/helpdesk_ticket/${ticketId}/assign`, {
            assigneeTo: assignTo
        });
    }

    possibleStatus$(ticketId: number): Observable<String[]> {
        return this._httpClient.get<String[]>(`api/helpdesk_ticket/${ticketId}/workflow`);
    }

    changeStatus$(ticketId: number, status: string): Observable<TicketResponse> {
        return this._httpClient.put<TicketResponse>(`api/helpdesk_ticket/${ticketId}/status`, {
            status: status
        });
    }

    changeSubcategory$(ticketId: number, subcategoryId: number) {
        return this._httpClient.put<TicketResponse>(`api/helpdesk_ticket/${ticketId}/subcategory`, {
            subcategoryId: subcategoryId
        });
    }

    shareWith$(ticketId: number, userId: any): Observable<TicketResponse> {
        return this._httpClient.put<TicketResponse>(`api/helpdesk_ticket/${ticketId}/share/with/${userId}`, {});
    }

    removeShareWith$(ticketId: number, userId: number): Observable<TicketResponse> {
        return this._httpClient.delete<TicketResponse>(`api/helpdesk_ticket/${ticketId}/withhold/with/${userId}`);
    }

    addComment$(id: number, formData: FormData): Observable<TicketResponse> {
        return this._httpClient.post<TicketResponse>(`api/helpdesk_ticket/${id}/log`, formData);
    }

    requestApproval$(ticketId: number, command: TicketRequestApprovalCommand): Observable<TicketResponse> {
        return this._httpClient.post<TicketResponse>(`api/helpdesk_ticket/${ticketId}/approval/request`, command);
    }
    escalate$(ticketId: number, command: TicketEscalateCommand): Observable<TicketResponse> {
        return this._httpClient.post<TicketResponse>(`api/helpdesk_ticket/${ticketId}/escalate`, command);
    }

    approvalApprove$(ticketId: number): Observable<TicketResponse> {
        return this._httpClient.post<TicketResponse>(`api/helpdesk_ticket/${ticketId}/approval/approve`, {});
    }

    approvalDeny$(ticketId: number, command: { comment: string }): Observable<TicketResponse> {
        return this._httpClient.post<TicketResponse>(`api/helpdesk_ticket/${ticketId}/approval/deny`, command);
    }

    getPendingCount(): Observable<number> {
        return this._httpClient.get<number>(`api/helpdesk_ticket/pending-count`)
            .pipe(
                tap((pending) => {
                    this._pendingCount.next(pending);
                }));
    }

    updatePendingCount(){
        this.getPendingCount()
            .subscribe(() => {
            });
    }

    get pendingCount$(): Observable<number> {
        return this._pendingCount.asObservable();
    }

    captureRanking$(publicId: string, value: number) {
        return this._httpClient.put(`api/helpdesk/ranking/${publicId}/${value}`, {});
    }
    downloadAttachment(ticketId: number, attachmentId: number) {
        return this._httpClient.get(`/api/helpdesk_ticket/${ticketId}/attachment/${attachmentId}/download`, {
            responseType: 'blob'
        });
    }

}

let priorityValues: KeyValue[] = [
    {
        key: 'LOW',
        value: 'Low'
    },
    {
        key: 'MEDIUM',
        value: 'Medium'
    },
    {
        key: 'HIGH',
        value: 'High'
    }
]

let statusValues: KeyValue[] = [
    {
        key: 'WAITING_SUPPORT',
        value: 'Waiting for support'
    },
    {
        key: 'WAITING_CUSTOMER',
        value: 'Waiting for customer'
    },
    {
        key: 'REQUESTED_APPROVAL',
        value: 'Request for Approval'
    },
    {
        key: 'ESCALATED',
        value: 'Escalated'
    },
    {
        key: 'IN_PROGRESS',
        value: 'In Progress'
    },
    {
        key: 'CANCELED',
        value: 'Canceled'
    },
    {
        key: 'RESOLVED',
        value: 'Resolved'
    },
    {
        key: 'CLOSED',
        value: 'Closed'
    }
]
