import {inject} from '@angular/core';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {forkJoin} from 'rxjs';
import {TicketService} from "./core/helpdesk/ticket.service";

export const initialDataResolver = () =>
{
    const navigationService = inject(NavigationService);
    const ticketService = inject(TicketService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        navigationService.get(),
        ticketService.getPendingCount()
    ]);
};
