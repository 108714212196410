<ng-container *ngIf="type === 'SMALL'">

    <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
        <ng-container *ngIf="pictureUrl">
            <img
                class="object-cover w-full h-full"
                [src]="pictureUrl"
                alt="Contact avatar"/>
        </ng-container>
        <ng-container *ngIf="!pictureUrl">
            <div class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                {{ name | name_to_avatar }}
            </div>
        </ng-container>
    </div>

</ng-container>

<ng-container *ngIf="type === 'BIG'">

    <div class="relative w-24 h-24">
        <img
            class="w-full h-full rounded-full"
            *ngIf="pictureUrl"
            [src]="pictureUrl"
            alt="User avatar">
        <mat-icon
            class="icon-size-24"
            *ngIf="!pictureUrl"
            [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
    </div>

</ng-container>


<ng-container *ngIf="type === 'PROFILE'">
    <div class="-mt-26 lg:-mt-22 rounded-full">
        <img
            class="w-32 h-32 rounded-full ring-4 ring-bg-card bg-accent-400"
            *ngIf="pictureUrl"
            [src]="pictureUrl"
            alt="User avatar">
        <mat-icon
            *ngIf="!pictureUrl"
            class="w-32 h-32 rounded-full ring-4 ring-bg-card bg-accent-400"
            [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
    </div>
</ng-container>
