import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
    name: 'name_to_avatar',
    standalone: true
})
export class SanitizeHtmlPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {
    }

    transform(name: string, ...args: unknown[]): string {
        let strings = name.split(' ');
        return strings[0].charAt(0) + strings[1].charAt(0)
    }



}
