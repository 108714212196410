import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {DateTime} from "luxon";
import {SocialAuthService} from "@abacritt/angularx-social-login";

@Injectable({providedIn: 'root'})
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private socialAuthService = inject(SocialAuthService);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get isAuthenticated(): boolean {
        return this.accessToken && !AuthUtils.isTokenExpired(this.accessToken)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', {email});
    }

    /**
     * Reset password
     *
     * @param password
     * @param token
     */
    resetPassword(token: string, password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', {password, token})
    }


    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => 'User is already logged in.');
        }

        return this._httpClient.post('api/auth', credentials, {
            headers: {
                'X-Time-Zone': DateTime.local().zoneName
            }
        }).pipe(
            switchMap((response: any) => {
                return this.afterLogin(response);
            })
        );
    }

    private afterLogin(response: any) {
        // Store the access token in the local storage
        this.accessToken = response.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        localStorage.setItem('user', JSON.stringify(response.userInfo));

        // Return a new observable with the response
        return of(response);
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post('api/auth/sign-in-with-token', {
            accessToken: this.accessToken,
        }).pipe(
            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.accessToken) {
                    this.accessToken = response.accessToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                localStorage.setItem('user', JSON.stringify(response.userInfo));

                // Return true
                return of(true);
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.clear();
        this.deleteAllCookies();


        // Set the authenticated flag to false
        this._authenticated = false;

        this.socialAuthService.signOut(false);

        // Return the observable
        return of(true);
    }

    private deleteAllCookies(): void {
        const cookies = document.cookie.split('; ');

        for (const cookie of cookies) {
            const [name] = cookie.split('=');
            this.deleteCookie(name);
        }
    }

    private deleteCookie(name: string): void {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.isAuthenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    signInGoogle$(authToken: string): Observable<any> {
        return this._httpClient.post('api/auth/google', {
                token: authToken
            },
            {
                headers: {
                    'X-Time-Zone': DateTime.local().zoneName
                }
            })
            .pipe(
                switchMap((response: any) => {
                    return this.afterLogin(response);
                })
            );
    }
}
