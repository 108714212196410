import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {UpdatePrivateUserCommand, User, UserDetailResponse, UserInfo, UserPublicSelfInfoResponse, UserSearchCommand, UserSearchPagingResponse, UserSearchResponse} from 'app/core/user/user.types';
import {map, Observable, of, ReplaySubject, tap} from 'rxjs';
import {Role} from "../common-types";


@Injectable({providedIn: 'root'})
export class UserService {
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------


    get currentUser(): User {
        return JSON.parse(localStorage.getItem('user'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            }),
        );
    }

    public search$(command: UserSearchCommand): Observable<UserSearchPagingResponse> {
        return this._httpClient.post<UserSearchPagingResponse>('api/user/search', command);
    }

    autocomplete(query: string, exceptIds: number[] = []): Observable<UserInfo[]> {

        let except = exceptIds.map(n => `except=${n}`).join("&")

        return this._httpClient.get<UserInfo[]>(`/api/user/autocomplete?query=${query}&${except}`);
    }

    findById$(id: number): Observable<UserDetailResponse> {
        return this._httpClient.get<UserDetailResponse>(`/api/user/${id}`);
    }

    allRoles$(): Observable<Role[]> {
        return this._httpClient.get<Role[]>(`/api/role`);
    }

    allContractType$() {
        return of(['EMPLOYEE', 'CONTRACTOR', 'INTERN'])
    }

    statuses$() {
        return of(['ACTIVE', 'INACTIVE'])
    }

    me$(): Observable<UserPublicSelfInfoResponse> {
        return this._httpClient.get<UserPublicSelfInfoResponse>(`/api/user/me`);
    }

    changePassword$(currentPassword: string, newPassword: string): Observable<any> {
        return this._httpClient.put(`/api/user/password`, {
            oldPassword: currentPassword,
            password: newPassword
        });
    }

    savePublic$(userId: number, value: any): Observable<any> {
        return this._httpClient.put(`/api/user/public/${userId}`, value);
    }

    savePrivate$(userId: number, value: UpdatePrivateUserCommand) {
        return this._httpClient.put(`/api/user/${userId}`, value);
    }

    findByIdForDirectory$(userId: number): Observable<UserDetailResponse> {
        return this._httpClient.get<UserDetailResponse>(`/api/directory/user/${userId}`);
    }


    allForDirectory$(query: string | null = null, companyId: number | null = null): Observable<UserSearchResponse[]> {
        return this._httpClient.get<UserSearchResponse[]>(`/api/directory/user/all`, {
            params: {
                query: query || '',
                companyId: companyId || ''
            }
        });
    }
}
