import {Injectable} from '@angular/core';
import {User} from "../user/user.types";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    public isUser: boolean;
    public isAdmin: boolean;
    public isHelpdesk: boolean;
    public isHelpdeskOrAdmin: boolean;
    public isTalentOrAdmin: boolean;
    public isManager: boolean;
    public isTalent: boolean;
    public isHelpdeskOrAdminOrManager: boolean;
    public currentUser: User;

    constructor() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.isUser = this.hasRole('USER');
        this.isAdmin = this.hasRole('ADMIN');
        this.isHelpdesk = this.hasRole('HELPDESK');
        this.isManager = this.hasRole('MANAGER');
        this.isTalent = this.hasRole('TALENT');
        this.isHelpdeskOrAdmin = this.isAdmin || this.isHelpdesk;
        this.isTalentOrAdmin = this.isAdmin || this.isTalent;
        this.isHelpdeskOrAdminOrManager = this.isAdmin || this.isHelpdesk || this.isManager;
    }

    public hasRole(role: string): boolean {
        return this.currentUser.roles.includes(role);
    }

    public isMe(id: number): boolean {
        return this.currentUser.id === id;
    }

}
