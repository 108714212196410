import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {SanitizeHtmlPipe} from "../../core/pipes/name-to-avatar.pipe";

@Component({
    selector: 'circular-avatar',
    standalone: true,
    imports: [CommonModule, MatIconModule, SanitizeHtmlPipe],
    templateUrl: './circular-avatar.component.html'
})
export class CircularAvatarComponent {

    @Input() pictureUrl: string;
    @Input() name: string;
    @Input() type: 'BIG' | 'SMALL' | 'PROFILE' = 'SMALL'
}
